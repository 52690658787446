import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable } from 'rxjs';
import { GetClauseDto, ClauseDto, BatchUpdateClauseDto } from '../models/playbooks.model';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class PlaybooksApiService extends ApiService {
  getClauses$(): Observable<GetClauseDto[]> {
    return this.get('/clauses');
  }

  createClause$(clause: ClauseDto): Observable<GetClauseDto> {
    return this.put('/clauses', clause);
  }

  updateClause$(clause: ClauseDto, uniqId: string): Observable<GetClauseDto> {
    return this.post(`/clauses/${uniqId}`, clause);
  }

  getClauseById$(uniqId: string): Observable<GetClauseDto> {
    return this.get(`/clauses/${uniqId}`);
  }

  deleteClause$(uniqueId: string): Observable<any> {
    return this.delete(`/clauses/${uniqueId}`);
  }

  batchUpdateClauses$(clauses: BatchUpdateClauseDto[]): Observable<GetClauseDto[]> {
    return this.post(`/clauses/batch-update`, clauses);
  }

  batchDeleteClauses$(ids: string[]): Observable<any> {
    return this.post(`/clauses/batch-delete`, ids);
  }
}

export const apiClausesProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiClauses.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiClauses.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiClauses.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiClauses.scopes.write],
  },
];
